import React, { useState } from "react";
import { Col, Form, Row, Typography } from "antd";
import {Link, useHistory} from "react-router-dom";
import ReCaptcha from "../ReCaptcha";

import "./styles.css";
import { AuthRest } from "../../../auth/SignInAuth";
import EmailVerificationBlock from "./EmailVerificationBlock";

import {
  CkButton,
  CkInput,
  CkCheckbox,
  CkIcon,
  CkMessage,
} from "../../../CkUI";

const authRest = new AuthRest();

interface IProps {
  back: Function;
}
const RegisterForm: React.FC<IProps> = ({ back }) => {
  const hist = useHistory();
  const [signUpForm] = Form.useForm();
  const [recaptchaValidation, setRecaptchaValidation] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [checkPassword, setCheckPassword] = useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [checkTC, setCheckTC] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const signUp = async () => {
    // Let's validate the fields
    // @ts-ignore
    const formData = await signUpForm
      .validateFields()
      .then((values: any) => values)
      .catch(() => false);

    // Is formData is equals to false, return false to prevent execution.
    if (formData === false) return false;

    setLoading(true);

    // Perform signUp request
    const signupResponse = await authRest
      .signUp(
        {
          firstName: formData.name,
          lastName: formData.lastname,
          email: formData.email,
          password: formData.password,
        },
        false
      )
      .then((response) => response);
    if (signupResponse.status === false) {
      CkMessage({ type: "error", text: signupResponse.message });
      setLoading(false);
    } else {
      // set user in context
      // setUser(signupResponse.data.user);
      // Close modal, drawe and clear form
      signUpForm.resetFields();
      window.location.replace("/configura-tu-taller");
    }
  };

  // @ts-ignore
  return (
    <Col >
      <Row justify="center">
        <Typography className="login-title-text">Crea una cuenta</Typography>
      </Row>
      <Row justify="center">
        <Typography className="login-text" style={{ marginBottom: "20px" }}>
          Con tu correo
        </Typography>
        {/* <Typography className="login-text" style={{ marginBottom: "20px" }}> */}
        {/*   Con tus redes sociales */}
        {/* </Typography> */}
      </Row>
      {/* <Row justify="center"> */}
      {/*   <SocialButtons action="signup" /> */}
      {/* </Row> */}
      {/* <Divider dashed orientation="center" style={{ margin: "12px 0" }} /> */}
      {/* <Row justify="center"> */}
      {/*   <Typography className="login-text">O con tu correo</Typography> */}
      {/* </Row> */}
      <Row>
        <Form form={signUpForm} className="login-form" layout="vertical">
          <CkInput
            name="email"
            extra="*Correo electrónico"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Ingresa el correo electrónico",
              },
              {
                type: "email",
                message: "Debes ingresar un correo electrónico",
              },
            ]}
            inputProps={{
              type: "email",
              value: email,
              onChange: (e: any) => setEmail(e.target.value),
              placeholder: "Escribe tu correo",
              maxLength: 256,
            }}
          />
          <EmailVerificationBlock
            isRegistered={false}
            email={email}
            emailVerified={emailVerified}
            setEmailVerified={setEmailVerified}
          />
          {emailVerified && (
            <React.Fragment>
              <CkInput
                name="name"
                extra="*Nombre(s)"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Ingresa el nombre de usuario",
                  },
                  {
                    pattern:
                      /^[A-Za-zÀ-ÖØ-öø-ÿ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ]*)*$/g,
                    message: "Escribe por lo menos un nombre válido",
                  },
                ]}
                inputProps={{
                  value: name,
                  disabled:
                    !email ||
                    !emailVerified ||
                    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)+$/.test(
                      email
                    ),
                  onChange: (e: any) => setName(e.target.value),
                  className: `input-bottom-border ${name ? "valid" : ""}`,
                  placeholder: "Escribe tu(s) nombre(s)",
                  maxLength: 50,
                }}
              />
              <CkInput
                name="lastname"
                extra="*Apellido(s)"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Ingresa el apellido de usuario",
                  },
                  {
                    pattern:
                      /^[A-Za-zÀ-ÖØ-öø-ÿ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ]*)*$/g,
                    message: "Escribe por lo menos un nombre válido",
                  },
                ]}
                inputProps={{
                  value: lastname,
                  disabled:
                    !name ||
                    !/^[A-Za-zÀ-ÖØ-öø-ÿ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ]*)*$/g.test(
                      name
                    ),
                  onChange: (e: any) => setLastname(e.target.value),
                  placeholder: "Escribe tu(s) apellido(s)",
                  maxLength: 50,
                }}
              />
              <CkInput
                name="password"
                extra="*Contraseña"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Ingresa la contraseña",
                  },
                  {
                    pattern:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-#?!@$%^.,;:&*+¿¡|°"()/={}\[\]]).{8,}$/,
                    message:
                      "Debe tener al menos 8 caracteres, minúsculas, mayúsculas, y por lo menos un número y un símbolo",
                  },
                ]}
                inputProps={{
                  type: "password",
                  disabled:
                    !lastname ||
                    !/^[A-Za-zÀ-ÖØ-öø-ÿ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ]*)*$/g.test(
                      lastname
                    ),
                  value: password,
                  onChange: (e: any) => setPassword(e.target.value),
                  placeholder: "Escribe tu contraseña",
                }}
                /* {...(!password
                  ? {
                      help: (
                        <div className="info-text">
                          <CkIcon name="information-round" />
                          <p>
                            Debe tener al menos 8 caracteres, minúsculas,
                            mayúsculas, y por lo menos un número y un símbolo.
                          </p>
                        </div>
                      ),
                    }
                  : {})} */
              />
              {!password && (
                <div className="info-text">
                  <CkIcon name="information-round" />
                  <p>
                    Debe tener al menos 8 caracteres, minúsculas, mayúsculas, y
                    por lo menos un número y un símbolo.
                  </p>
                </div>
              )}
              <CkInput
                name="checkPassword"
                extra="*Comprueba tu contraseña"
                hasFeedback
                validateStatus={`${
                  checkPassword
                    ? checkPassword === password
                      ? "success"
                      : "error"
                    : ""
                }`}
                rules={[
                  {
                    required: true,
                    message: "Ingresa de nuevo la contraseña",
                  },
                ]}
                inputProps={{
                  type: "password",
                  disabled:
                    !lastname ||
                    !/^[A-Za-zÀ-ÖØ-öø-ÿ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ]*)*$/g.test(
                      lastname
                    ),
                  onChange: (e: any) => setCheckPassword(e.target.value),
                  placeholder: "Escribe de nuevo tu contraseña",
                }}
              />
              <ReCaptcha
                styles={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
                setRecaptchaValidation={setRecaptchaValidation}
              />
            </React.Fragment>
          )}
        </Form>
      </Row>

      {emailVerified && (
        <React.Fragment>
          <div className="terms-container">
            <CkCheckbox
              noMargin
              checkboxProps={{
                onChange: () => setCheckTC(!checkTC),
              }}
            >
              He leído y estoy de acuerdo con los
              <Link to="/terminos" target="_blank">
                <span className="mobile">T y C</span>
                <span className="desktop">Términos y Condiciones</span>
              </Link>
            </CkCheckbox>
          </div>
          <div className="buttons-container centered">
            <CkButton
              loading={loading}
              onClick={signUp}
              disabled={
                !recaptchaValidation ||
                !checkTC ||
                !name ||
                !lastname ||
                !email ||
                !password ||
                !checkPassword ||
                password !== checkPassword
              }
            >
              Crear cuenta
            </CkButton>
          </div>
        </React.Fragment>
      )}
      <Row justify="center">
        <CkButton className="cta-login" variant="link" onClick={back}>
          ¿Ya tienes una cuenta? Inicia sesión
        </CkButton>
      </Row>
      <Row>
        <div style={{padding: "3%", margin: "20px 0 20px 0", backgroundColor: "#2369BD", borderRadius: "10px"}}>
          <p style={{fontSize: "16px", color: "#FFFFFF", margin: 0}}>
            ¿Quieres saber más sobre cómo CarKer puede transformar tu taller?
          </p>
          <p style={{fontSize: "16px", color: "#FFFFFF", margin: 0}}>
            <span style={{fontWeight: 600, textDecoration: "underline", cursor: "pointer"}} onClick={() => {
              hist.push("/")
            }}>Descubre aquí</span> los beneficios que tenemos para ofrecerte.
          </p>
        </div>
      </Row>
    </Col>
  );
};

export default React.memo(RegisterForm);
