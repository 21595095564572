import React, { useState } from "react";
import {
  Col,
  Divider,
  Row,
  Typography,
  Form,
} from "antd";
import { encryptHMAC } from "../../Utilities/Encryption";
import { AuthRest } from "../../../auth/SignInAuth";
import { UserAPI } from "../../Services/axios";
import "./styles.css";
import { CkInput, CkButton, CkMessage } from "../../../CkUI";
import CkInputPassword from "../../../CkUI/components/molecules/CkInputPassword";
import {useHistory} from "react-router-dom";

const authRest = new AuthRest();
interface IProps {
  loadRegisterForm: Function;
  goToResetPassword: Function;
}
const LoginForm: React.FC<IProps> = ({
  loadRegisterForm,
  goToResetPassword,
}) => {
  const hist = useHistory();
  const [signInForm] = Form.useForm();
  const [resetPasswordForm] = Form.useForm();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mustResetPassword, setMustResetPassword] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const resetForms = () => {
    // @ts-ignore
    signInForm.resetFields();
    setPassword("");
    setEmail("");
  };

  const login = async () => {
    setLoading(true);

    // Let's validate the fields
    const formData = await signInForm
      // @ts-ignore
      .validateFields()
      .then((values: any) => values)
      .catch(() => false);

    // Is formData is equals to false, return false to prevent excecution.
    if (formData === false) {
      setLoading(false);
      return false;
    }

    const checkUser = await UserAPI.checkUser(email);

    if (!!checkUser.data === false) {
      CkMessage({
        type: "error",
        text: "Se produjo un error al iniciar sesión.",
      });
      setLoading(false);
      return false;
    }

    if (checkUser.data.isWSUser === false) {
      setLoading(false);
      CkMessage({
        type: "error",
        text: "Este correo electrónico no se encuentra registrado en nuestra plataforma.",
      });
      return;
    }

    if (checkUser.data.isWSUser && checkUser.data.source !== "") {
      setLoading(false);
      CkMessage({
        type: "error",
        text: [
          "Este correo electrónico se encuentra registrado una red social.",
          ...(checkUser.data.source.includes("google")
            ? ["Inicie sesión con su cuenta de Google."]
            : []),
          ...(checkUser.data.source.includes("facebook")
            ? ["Inicie sesión con su cuenta de Facebook."]
            : []),
        ].join(" "),
      });
      return;
    }

    if (checkUser && checkUser.data.isB2CUser === true) {
      // Perform login request
      const loginResponse = await authRest
        .login({
          username: formData.email,
          password: formData.password,
        })
        .then((response) => response);
      // let's check if there are some error
      if (loginResponse.status === false) {
        CkMessage({ type: "error", text: loginResponse.message });
      } else {
        if (loginResponse.data.user.extension_MustResetPassword) {
          setMustResetPassword(true);
        } else {
          window.location.replace("/");
        }
      }
    } else {
      CkMessage({
        type: "error",
        text: "El usuario o contraseña indicados no es válido",
      });
    }
    setLoading(false);
  };

  const resetPassword = async () => {
    // Let's validate the fields
    const formData = await resetPasswordForm
      // @ts-ignore
      .validateFields()
      .then((values: any) => values)
      .catch(() => false);

    // Is formData is equals to false, return false to prevent excecution.
    if (formData === false) return false;

    setLoading(true);

    const encryptedPassword = encryptHMAC(formData.newPassword);
    const passwordChangeRequest = await UserAPI.updatePassword(
      encryptedPassword,
      false
    )
      .then((response) => response)
      .catch((error) => {
        console.error("error", error);
        return false;
      });

    setLoading(false);

    if (passwordChangeRequest === false) {
      CkMessage({
        type: "error",
        text: "Ha ocurrido un error, vuelva a intentarlo.",
      });
    } else {
      window.location.replace("/citas");
    }
  };

  const validateForm = (_: any[], allFields: any[]) => {
    setDisableButton(
      allFields.some(
        (field) =>
          (field.touched === true && field.errors && field.errors.length > 0) ||
          field.touched === false
      )
    );
  };

  // @ts-ignore
  return (
    <Col>
      <Row justify="center">
        <Typography className="login-text vertical-margin">
          Inicia sesión
        </Typography>
      </Row>
      <Row>
        <Form form={signInForm} className="login-form" layout="vertical">
          <CkInput
            className="login-input-text"
            name="email"
            extra="Correo electrónico"
            rules={[
              {
                required: true,
                type: "email",
                message: "Ingresa el usuario o el correo electrónico",
              },
            ]}
            inputProps={{
              value: email,
              onChange: (e: any) => {setEmail(e.target.value); signInForm.setFieldValue("email", e.target.value)},
              placeholder: "Escribe tu correo",
              maxLength: 256,
              onPressEnter: login,
              disabled: mustResetPassword,
            }}
          />
          <CkInputPassword
            className="login-input-text register-password-input"
            name="password"
            extra="Contraseña"
            rules={[
              {
                required: true,
                message: "Ingresa la contraseña",
              },
            ]}
            inputProps={{
              style: { fontSize: "16px" },
              type: "password",
              value: password,
              onChange: (e: any) => setPassword(e.target.value),
              placeholder: "Escribe tu contraseña",
              onPressEnter: login,
              disabled: mustResetPassword,
            }}
          />
        </Form>
      </Row>
      {mustResetPassword && (
        <>
          <Divider dashed orientation="center" />
          <Row justify="center">
            <Typography className="login-text spaced">
              Debe cambiar su contraseña temporal para continuar:
            </Typography>
          </Row>
          <Row>
            <Form
              form={resetPasswordForm}
              className="login-form reset-password-form"
              layout="vertical"
              onFieldsChange={validateForm}
            >
              <CkInput
                name="newPassword"
                extra="Nueva contraseña"
                rules={[
                  {
                    pattern: new RegExp(
                      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})|^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})|^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})|^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/gm
                    ),
                    message:
                      "Mínimo 8 caracteres y máximo 64 caracteres de longitud, 3 de 4 clases de caracteres: mayúsculas, minúsculas, número, símbolo",
                  },
                ]}
                inputProps={{
                  placeholder: "Nueva contraseña",
                }}
              />
              <CkInput
                name="passwordConfirmation"
                extra="Confirmar nueva contraseña"
                rules={[
                  {
                    required: true,
                    message: "Confirme su contraseña",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Las contraseñas que ingresaste no coinciden"
                      );
                    },
                  }),
                ]}
                inputProps={{
                  placeholder: "Confirmar nueva contraseña",
                }}
              />
            </Form>
          </Row>
        </>
      )}
      {mustResetPassword === false && (
        <Row justify="end">
          <CkButton
            block={false}
            variant="link"
            className="login-link-text"
            onClick={goToResetPassword}
            style={{ height: "100%", padding: "7px 0 15px" }}
          >
            Recupera tu contraseña
          </CkButton>
        </Row>
      )}
      <div
        className={[
          "buttons-container",
          ...(mustResetPassword ? ["centered"] : []),
        ].join(" ")}
      >
        {mustResetPassword === false && (
          <>
            <CkButton block onClick={loadRegisterForm} variant="secondary">
              Crear cuenta
            </CkButton>
            <CkButton block loading={loading} onClick={login}>
              Iniciar sesión
            </CkButton>
          </>
        )}

        {mustResetPassword && (
          <CkButton
            block
            loading={loading}
            disabled={disableButton}
            onClick={resetPassword}
          >
            Confirmar
          </CkButton>
        )}
      </div>
      {
        !mustResetPassword && (
          <div style={{ padding: "3%", margin: "20px 0 20px 0", backgroundColor: "#2369BD", borderRadius: "10px" }}>
            <p style={{ fontSize: "16px", color: "#FFFFFF", margin: 0 }}>
              ¿Quieres saber más sobre cómo CarKer puede transformar tu taller?
            </p>
            <p style={{ fontSize: "16px", color: "#FFFFFF", margin: 0 }}>
              <span style={{ fontWeight: 600, textDecoration: "underline", cursor: "pointer"}} onClick={() => { hist.push("/")}}>Descubre aquí</span> los beneficios que tenemos para ofrecerte.
            </p>
          </div>
        )
      }
    </Col>
  );
};

// @ts-ignore
export default React.forwardRef(LoginForm);
