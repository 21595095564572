import React, { Fragment, memo } from "react";
import {Col, Divider, Row} from "antd";
import { CkButton, CkIcon } from "../../../CkUI";
import { useHistory } from "react-router-dom";
import { useIsMobileScreen } from "../../Utilities";
import "./style.css";


interface IProps {
}

const LandingFooter: React.FC<IProps> = ({ }: IProps) => {
  const history = useHistory();
  const isMobile = useIsMobileScreen(967);

  return (
    <Fragment>
      <Row className="landing-footer-first-container">
        <Col lg={12} md={24} className="bw-logo-container">
          <CkIcon name="carker-logo-bw" width="" height="" fill="" />
          <p className="carker-logo-bw-subtitle">Transformamos el mundo del cuidado automotriz</p>
        </Col>
        <Col lg={12} md={24} style={{ display: "flex", flexDirection: "row-reverse"}}>
          <CkButton
            className="terms-button-landing-footer"
            type="text"
            onClick={() => {
              history.push("terminos");
            }}
            style={{ color: "#FFFFFF"}}
          >
            Términos y condiciones
          </CkButton>
        </Col>
        {
          isMobile && (
            <Col lg={12} md={24} style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "center",
              paddingTop: "20px",
            }}>

              <a
                href="mailto:soporte@carker.com"
                style={{ cursor: "pointer", color: "#FFFFFF", fontSize: "14px", fontWeight: 600}}
              >
                soporte@carker.com
              </a>
              <Divider type="vertical" style={{ marginTop: "5px",color: "#FFFFFF", height: "1em", borderLeft: "1px solid #FFF"}}/>
              <a
                rel="noopener noreferrer"
                href={"https://wa.me/525564359958"}
                target="_blank"
                style={{color: "#FFFFFF", fontSize: "14px", fontWeight: 600}}
              >
                55 2282 0800
              </a>
            </Col>
          )
        }
      </Row>
      <Row style={{ backgroundColor: "#000", color: "#FFF", padding: "5px 5% 5px 5%"}} justify="center">
        <Col lg={12} md={24}>
          <p style={{ margin: 0 }}>
            Ⓒ {new Date().getFullYear().toString()} CarKer. Todos los derechos reservados
          </p>
        </Col>
        {
          !isMobile && (
            <Col lg={12} md={24} style={{
              display: "flex",
              flexDirection: "row-reverse",
              paddingRight: "40px"
            }}>

              <a
                href="mailto:soporte@carker.com"
                style={{ cursor: "pointer", color: "#FFFFFF", fontSize: "14px", fontWeight: 600}}
              >
                <CkIcon name="envelop" width="" height="" fill="" style={{ padding: "0 7px 0 25px"}}/>soporte@carker.com
              </a>
              <a
                rel="noopener noreferrer"
                href={"https://wa.me/525564359958"}
                target="_blank"
                style={{color: "#FFFFFF", fontSize: "14px", fontWeight: 600}}
              >
                <CkIcon name="phone" width="" height="" fill="" style={{ padding: "0 7px 0 25px"}}/>55 2282 0800
              </a>
            </Col>
          )
        }

      </Row>
    </Fragment>
  );
};

export default memo(LandingFooter);
