import React, { useState } from "react";
import { Button, Col, Form, Row } from "antd";

import "./styles.css";
import { encryptHMAC, getImageBaseUrl } from "../../Utilities";
import { axios } from "shared";
import EmailVerificationBlock from "./EmailVerificationBlock";
import "./styles.css";
import { CkButton, CkInput, CkMessage } from "../../../CkUI";
import CkInputPassword from "../../../CkUI/components/molecules/CkInputPassword";

const Logo = getImageBaseUrl("logos/logo.svg");

interface IProps {
  goToStart: Function;
}
const ChangePasswordForm: React.FC<IProps> = ({ goToStart }) => {
  const [changePasswordForm] = Form.useForm();

  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [checkPassword, setCheckPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [emailVerified, setEmailVerified] = useState<boolean>(false);

  const resetForms = () => {
    changePasswordForm.resetFields();
    setPassword("");
    setCheckPassword("");
    setEmail("");
    setEmailVerified(false);
  };

  const changePassword = () => {
    changePasswordForm
      .validateFields()
      .then((values: any) => {
        setLoading(true);
        let ep = encryptHMAC(values.password, false);
        axios
          .put("/b2c/users?isEndConsumer=false", {
            emailaddress: email,
            password: ep,
          })
          .then((result) => {
            CkMessage({
              type: "success",
              text: "Contraseña cambiada con éxito. Puedes iniciar sesión con tu nueva contraseña."
            });
            goToStart();
            resetForms();
          })
          .catch((err) => {
            alert("Ha ocurrido un error, vuelva a intentarlo.");
          })
          .finally(() => setLoading(false));
      })
      .catch((err: any) => {
        CkMessage({type: "warning", text: "Verifica todos los campos del formulario."});
      });
  };

  return (
    <Col>
      <div className="login-box-container">
        <div
          className="login-box"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <img src={Logo} alt="CarKer logo" style={{ width: "150px" }} />
          {emailVerified ? (
            <div>
              <h1
                className="blue"
                style={{ fontSize: "24px", textAlign: "center" }}
              >
                Cambiar contraseña
              </h1>
              <p
                className="blue"
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontFamily: "emprint-semibold",
                }}
              >
                Establece una nueva contraseña
              </p>
              <Form
                form={changePasswordForm}
                className="login-form reset-password-form nav-forms"
              >
                <CkInputPassword
                  className="register-password-input"
                  name="password"
                  extra="Contraseña"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Ingresa la contraseña",
                    },
                    {
                      pattern:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^.,;:&*-+¿¡|°"()/={}\[\]]).{8,}$/,
                      message:
                        "Debe tener al menos 8 caracteres, minúsculas, mayúsculas, y por lo menos un número y un símbolo",
                    },
                  ]}
                  inputProps={{
                    value: password,
                    onChange: (e: any) => setPassword(e.target.value),
                    placeholder: "Escribe tu contraseña",
                  }}
                />

                {!password && (
                  <p className="black-70" style={{ fontSize: "12px" }}>
                    Debe tener al menos 8 caracteres, minúsculas, mayúsculas, y
                    por lo menos un número y un símbolo
                  </p>
                )}

                <CkInputPassword
                  className="register-password-input"
                  name="checkPassword"
                  extra="Comprueba tu contraseña"
                  style={{ paddingBottom: "20px" }}
                  hasFeedback
                  validateStatus={`${
                    checkPassword
                      ? checkPassword === password
                        ? "success"
                        : "error"
                      : ""
                  }`}
                  rules={[
                    {
                      required: true,
                      message: "Ingresa de nuevo la contraseña",
                    },
                  ]}
                  inputProps={{
                    // value: checkPassword,
                    onChange: (e: any) => setCheckPassword(e.target.value),
                    placeholder: "Escribe de nuevo tu contraseña",
                  }}
                />
                <div className="buttons-container centered">
                  <CkButton
                    variant="primary"
                    color="primary"
                    disabled={password !== checkPassword || loading}
                    onClick={changePassword}
                  >
                    Establecer nueva contraseña
                  </CkButton>
                </div>
              </Form>
            </div>
          ) : (
            <div>
              <h1
                className="blue vertical-margin"
                style={{ fontSize: "24px", textAlign: "center" }}
              >
                Recupera tu contraseña
              </h1>

              <Form form={changePasswordForm}>
                <CkInput
                  name="email"
                  extra="Correo electrónico"
                  // className="login-input-text"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Ingresa el correo electrónico",
                    },
                    {
                      // pattern:
                      //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)+$/,
                      type: "email",
                      message: "Debes ingresar un correo electrónico",
                    },
                  ]}
                  inputProps={{
                    value: email,
                    placeholder: "Escribe tu correo",
                    onChange: (e: any) => setEmail(e.target.value),
                    className: "input-bottom-border",
                    maxLength: 256,
                  }}
                />
              </Form>
              <EmailVerificationBlock
                email={email}
                emailVerified={emailVerified}
                setEmailVerified={setEmailVerified}
                isRegistered={true}
              />
            </div>
          )}
          <Row justify="center">
            <Button type="link" className="login-link-text" onClick={goToStart}>
              ¿Ya tienes una cuenta? Inicia sesión
            </Button>
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default React.memo(ChangePasswordForm);
