import React, { useState } from "react";
import {getImageBaseUrl, useIsMobileScreen} from "../../Utilities";
// import Logo from "../../../assets/Images/CarKer_logo_newvisual.png";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import './styles.css';
import WorkshopCommitmentsWidget from "../../../Screens/LoginPage/WorkshopCommitmentsWidget";

const Logo = getImageBaseUrl("logos/logo.svg");

interface IProps {
  defaultBox?: "login" | "register",
  goToResetPassword: Function,
}

const LoginBox: React.FC<IProps> = ({
  defaultBox,
  goToResetPassword
}) => {
  const [isLogin, setIsLogin] = useState<boolean>(defaultBox === "login");
  const isMobile = useIsMobileScreen(991);
  return (
    <div className="login-box-container">
      <div className="login-box">
        <img
          src={Logo}
          alt="CarKer logo"
          style={{ width: '150px' }}
        />
        {
          isLogin ? (
            <LoginForm
              loadRegisterForm={() => setIsLogin(false)}
              goToResetPassword={goToResetPassword}
            />
          ) : (
            <RegisterForm
              back={() => setIsLogin(true)}
            />
          )
        }
      </div>
      <div style={{ display: "flex", height: "100%", minHeight: "80px", width: "100%", justifyContent: "center", alignItems: "center", padding: "5%"}}>
        {
          //isMobile && <WorkshopCommitmentsWidget />
        }
      </div>
    </div>
  );
};

export default React.memo(LoginBox);
