import React, { useRef, useState } from "react";
import { Form, message, Input, Button } from "antd";
import "./styles.css";
import { encryptHMAC, hashSha256 } from "../../Utilities";
import { UserAPI } from "../../Services/axios";
import axios from "axios";
import "./styles.css";
import { CkButton, CkIcon, CkMessage } from "../../../CkUI";

axios.defaults.baseURL = process.env.REACT_APP_BASEURL as string;

interface IProps {
  isRegistered: boolean;
  email: string;
  emailVerified: boolean;
  setEmailVerified: Function;
}

const EmailVerificationBlock: React.FC<IProps> = ({
  email,
  emailVerified,
  setEmailVerified,
  isRegistered,
}) => {
  const [OTPSended, setOTPSended] = useState<boolean>(false);
  const [blockSendEmail, setBlockSendEmail] = useState<boolean>(false);
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const otpRef = useRef<any>([]);

  const resetForms = () => {
    setOtp(["", "", "", "", "", ""]);
    setOTPSended(false);
    setEmailVerified(false);
  };

  const checkUser = async () => {
    const userCheckResult = await UserAPI.checkUser(email);
    // If the user is registred
    if (isRegistered) {
      if (userCheckResult.data.isEndConsumer === true) {
        CkMessage({
          type: "error",
          text: "Este correo electrónico se encuentra registrado como CarKer para usuarios. Debe recuperar el usuario en CarKer.com"
        });
        return;
      }
      if (userCheckResult.data.isWSUser === false) {
        CkMessage({
          type: "error",
          text: "Este correo electrónico no se encuentra registrado en nuestra plataforma."
        });
        return;
      }
      if (userCheckResult.data.isWSUser && userCheckResult.data.source !== "") {
        CkMessage({
          type: "error",
          text: [
            "Este correo electrónico se encuentra registrado una red social.",
            ...(userCheckResult.data.source.includes("google")
              ? ["Inicie sesión con su cuenta de Google."]
              : []),
            ...(userCheckResult.data.source.includes("facebook")
              ? ["Inicie sesión con su cuenta de Facebook."]
              : []),
          ].join(" "),
        });
        return;
      }
      sendVerificationCode(email);

      // Else, unregistred user
    } else {
      if (
        userCheckResult.data.isEndConsumer &&
        userCheckResult.data.source.includes("mobilehub")
      ) {
        CkMessage({
          type: "error",
          text: "Este correo electrónico ya está registrado en Mobil Hub. Por favor, utiliza otra dirección de correo electrónico para registrarse en CarKer para talleres.",
        });
        return;
      }
      if (userCheckResult.data.isWSUser) {
        CkMessage({
          type: "error",
          text: [
            "Este correo electrónico ya se encuentra registrado en nuestra plataforma.",
            ...(userCheckResult.data.source.includes("google")
              ? ["Inicie sesión con su cuenta de Google."]
              : []),
            ...(userCheckResult.data.source.includes("facebook")
              ? ["Inicie sesión con su cuenta de Facebook."]
              : []),
            ...(userCheckResult.data.source === ""
              ? ["Inicie sesión con su correo y contraseña."]
              : []),
          ].join(" "),
        });
        return;
      }
      sendVerificationCode(email);
    }
  };

  const sendVerificationCode = async (email: string) => {
    setBlockSendEmail(true);
    axios
      .post("/notifications/verificationCodeNotifications", {
        emailAddress: email,
        isEndConsumer: false,
      })
      .then((data: any) => {
        CkMessage({
          type: "success",
          text: "Se envió el código de verificación, revisa tu correo electrónico"
        });
        localStorage.setItem("OTP", data.data.verificationCode);
        setOTPSended(true);
        setTimeout(() => {
          localStorage.removeItem("OTP");
        }, 900000);
        setBlockSendEmail(false);
      })
      .catch(() => {
        CkMessage({type: "error", text: "No se pudo enviar el código de verificación"});
      });
  };

  const VerifyOTP = (paste?: string[]) => {
    const hashedOtp = hashSha256((paste || otp).join(""));
    if (hashedOtp === localStorage.getItem("OTP")) {
      setEmailVerified(true);
      CkMessage({type: "success", text: "Correo electrónico comprobado, puede continuar"});
      localStorage.removeItem("OTP");
    } else {
      CkMessage({type: "error", text: "El código de verificación es inválido"});
    }
  };

  const changeFocus = (index: number) => {
    if (index < 5) otpRef.current[index + 1].focus();
  };

  // @ts-ignore
  return (
    <div className="email-verification-box content-wrapper">
      {emailVerified ? (
        <div />
      ) : (
        <div>
          <p className="action-message">
            {OTPSended
              ? "Escribe el código de verificación que enviamos a tu correo"
              : "Escribe tu correo y te enviaremos un código de verificación"}
          </p>
          <div className="digits-input-container">
            <div>
              {[...Array(3)].map((_, index) => (
                <Input
                  key={`otp${index}`}
                  ref={(el: any) => {
                    if (!otpRef.current[index]) {
                      if (index === 0) {
                        el?.input.addEventListener("paste", (event: any) => {
                          event.preventDefault();
                          event.stopPropagation();
                          let paste = (
                            event.clipboardData || window.clipboardData
                          )?.getData("text");
                          paste = paste.slice(0, 6).split("");
                          setOtp(paste);
                          changeFocus(4);
                          VerifyOTP(paste);
                        });
                      }
                    }
                    otpRef.current[index] = el;
                  }}
                  type="number"
                  size="large"
                  value={otp[index]}
                  className="digits-input"
                  onChange={(evt: any) => {
                    evt.preventDefault();
                    const temp = [...otp];
                    temp[index] = evt.target.value[0];
                    setOtp(temp);
                    if (evt.target.value.length > 0) changeFocus(index);
                  }}
                  hidden={!OTPSended}
                />
              ))}
            </div>
            <div>
              {[...Array(3)].map((_, index) => (
                <Input
                  type="number"
                  key={`otp${index + 3}`}
                  ref={(el: any) => (otpRef.current[index + 3] = el)}
                  size="large"
                  value={otp[index + 3]}
                  className="digits-input"
                  onChange={(evt: any) => {
                    evt.preventDefault();
                    const temp = [...otp];
                    temp[index + 3] = evt.target.value[0];
                    setOtp(temp);
                    if (evt.target.value.length > 0) changeFocus(index + 3);
                  }}
                  hidden={!OTPSended}
                />
              ))}
            </div>
          </div>
          <div className="verify-email-buttons">
            <CkButton
              block
              hidden={OTPSended}
              disabled={blockSendEmail}
              onClick={() => checkUser()}
            >
              Envíar código de verificación
            </CkButton>
            {OTPSended && (
              <div className="info-text">
                <CkIcon name="information-round" />
                <p>
                  ¿No ves el correo de verificación? Revisa tu carpeta de spam y
                  haz clic en 'Reenviar código' si no lo encuentras.
                </p>
              </div>
            )}
            <CkButton hidden={!OTPSended} onClick={() => VerifyOTP()} block>
              Verificar código
            </CkButton>
            <CkButton
              block
              hidden={!OTPSended}
              onClick={checkUser}
              variant="secondary"
            >
              Reenviar código
            </CkButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(EmailVerificationBlock);
